import Axios, { AxiosError } from 'axios'

export const interpretErrorMessage = (data: string) => {
  const [code, message] = data.split(':')
  if (code === 'OPS_ERROR') {
    return
  }
  return message ?? data
}

export const fetchJSON = async (path: string) => {
  const response = await fetch(path)
  const data = await response.json()
  if (!response.ok) throw new Error(data)
  return data
}

export const useApi = () => {
  const { apiUrl } = useRuntimeConfig().public
  const token = localStorage.getItem('token')
  const toast = useToast()
  const queryClient = useQueryClient()

  const { addToast } = toast
  const onError = (error: unknown) => {
    if (!(error instanceof AxiosError)) {
      return alert(error)
    }
    const status = error.status ?? 0
    if (status > 499 || status === 413) {
      return addToast({
        type: 'error',
        title: 'Something went wrong on our end'
      })
    }
    const data: ApiError = error.response?.data
    if (data.message) {
      const message = interpretErrorMessage(data.message)
      return message && addToast({ type: 'error', title: message })
    }
    data.errors.forEach(({ message }) => {
      addToast({ type: 'error', title: interpretErrorMessage(message) })
    })
  }

  const refetchQuery = (queryKey?: readonly unknown[]) =>
    queryClient.invalidateQueries(queryKey && { queryKey })

  const getNextPageParam = (lastPage: ApiList<any> | null) =>
    lastPage &&
    lastPage.meta &&
    lastPage.meta.currentPage < lastPage.meta.lastPage
      ? lastPage.meta?.currentPage + 1
      : undefined

  const axios = Axios.create({
    baseURL: apiUrl ?? 'https://live-api-inventr-ipm-6b5xtsmgaq-uc.a.run.app',
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      'Content-Type': 'application/json'
    }
  })
  return { axios, getNextPageParam, onError, queryClient, refetchQuery, toast }
}

export const useSort = (defaultSort: Sort) => {
  const route = useRoute()
  const sort = computed((): Sort => {
    const sortBy =
      typeof route.query.sortBy === 'string'
        ? route.query.sortBy
        : defaultSort.sortBy
    const sortOrder = route.query.sortOrder
      ? (route.query.sortOrder as Sort['sortOrder'])
      : defaultSort.sortOrder
    return { sortBy, sortOrder }
  })

  // methods
  const setSort = (value: Sort) =>
    navigateTo({ query: { ...route.query, ...value } })

  return { sort, setSort }
}
